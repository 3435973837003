import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import * as React from 'react'
import { Layout } from 'components/Layout'
import Seo from 'components/Layout/Seo'
import { 
  TestimonialsSlice,
  SuccessSlice,
  TemplateToTrySlice,
  HeroSlice,
  ClientsSlice,
  FeaturesTeaserWindowsSlice,
  FAQSlice,
} from 'components/Slices'

const IndexPage = () => {

  const data = useStaticQuery(graphql`
    query LandingABTestingQuery {
      hero: file(relativePath: { eq: "hero.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            width: 1400
            placeholder: NONE
            formats: [AUTO, WEBP, AVIF]
          )
        }
        publicURL
      }
      aws: file(relativePath: { eq: "aws-qualified.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            width: 100
            placeholder: NONE
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `)

  const pageName = 'Semplates - The AWS SES Template Editor'
  const subtitle =
    "Unlock the full potential of Amazon SES with Semplates' email template editor. Design and publish personalized, responsive and branded emails with a few clicks."
  const embedVideo = 'https://www.youtube.com/embed/EzLPtkqbD3I?autoplay=1'
  const video = 'https://www.youtube.com/watch?v=EzLPtkqbD3I&t=5s'
  const image = getImage(data.hero)
  const awsQualifiedImage = getImage(data.aws)
  const script = [
    {
      '@type': 'VideoObject',
      name: pageName,
      description: subtitle,
      thumbnailUrl: [`https://semplates.io${data.hero.publicURL}`],
      embedUrl: embedVideo,
      contentUrl: video,
      uploadDate: '2023-08-04T08:00:00+00:00',
    },
  ]

  const breadcrumbList = [
    { position: 1, name: pageName, path: 'landing' },
  ]

  return (
    <Layout>
      <Seo
        title={pageName}
        breadcrumbList={breadcrumbList}
        organizationLogo
        script={script}
      />
      <HeroSlice image={image} awsQualifiedImage={awsQualifiedImage} />
      <ClientsSlice />
      <FeaturesTeaserWindowsSlice />
      <SuccessSlice />
      <TestimonialsSlice />
      <TemplateToTrySlice />
      <FAQSlice />
    </Layout>
  )
}

export default IndexPage
